<template>
  <div>
    <view-service
      :service-data="serviceData"
      :service-history="null"
    />
  </div>
</template>
<script>
// eslint-disable-next-line import/extensions
import ViewService from '@/views/Services/Components/ServiceCard'

export default {
  name: 'ServiceCard',
  components: {
    ViewService,
  },
  props: {
    serviceData: Object,
  },
}
</script>

<style scoped>

</style>
