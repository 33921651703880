<template>
  <b-row>
    <b-col>
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col>
              <div class="font-weight-bold text-primary">
                İlgili Kişi
              </div>
              {{ serviceData.related_person }}
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Telefon
              </div>
              {{ serviceData.related_phone }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="serviceData.notes">
          <div class="font-weight-bold text-primary">
            Yorum / Notlar
          </div>
          {{ serviceData.notes }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
      cols="12"
      class="mt-2"
    >
      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th class="align-middle">
              NUMUNE BİLGİLERİ
            </b-th>
            <b-th
              class="align-middle text-center"
            >
              DEĞERLER
            </b-th>
            <b-th class="text-center align-middle">
              BİRİMLER
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>Analiz Tarihi</b-td>
            <b-td class="text-center">
              {{ (serviceData.analysed)? moment(serviceData.analysed).format('DD.MM.yyyy') : '-' }}
            </b-td>
            <b-td />
          </b-tr>
          <b-tr>
            <b-td>Kullanılan Proses</b-td>
            <b-td class="text-center">
              {{ (serviceData.id_used_processes)? selectedData(usedProcesses,serviceData.id_used_processes) : '-' }}
            </b-td>
            <b-td />
          </b-tr>
          <b-tr>
            <b-td>Kullanılan Ürün</b-td>
            <b-td class="text-center">
              {{ (serviceData.id_used_products)? selectedData(usedProducts,serviceData.id_used_products) : '-' }}
            </b-td>
            <b-td />
          </b-tr>
          <b-tr>
            <b-td>pH</b-td>
            <b-td class="text-center">
              {{ (serviceData.ph)? serviceData.ph : '-' }}
            </b-td>
            <b-td />
          </b-tr>
          <b-tr>
            <b-td>İletkenlik</b-td>

            <b-td class="text-center">
              {{ (serviceData.conductivity)? serviceData.conductivity : '-' }}
            </b-td>
            <b-td class="text-center">
              µs/cm
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Toplam Alkalite</b-td>

            <b-td class="text-center">
              {{ (serviceData.total_alkalinity)? serviceData.total_alkalinity : '-' }}
            </b-td>
            <b-td class="text-center">
              ml/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Toplam Asit</b-td>

            <b-td class="text-center">
              {{ (serviceData.total_acid)? serviceData.total_acid : '-' }}
            </b-td>
            <b-td class="text-center">
              ml/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Serbest Asit</b-td>

            <b-td class="text-center">
              {{ (serviceData.free_acid)? serviceData.free_acid : '-' }}
            </b-td>
            <b-td class="text-center">
              ml/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Hızlandırıcı</b-td>

            <b-td class="text-center">
              {{ (serviceData.accelerator)? serviceData.accelerator : '-' }}
            </b-td>
            <b-td />
          </b-tr>
          <b-tr>
            <b-td>Fosfat Noktası</b-td>

            <b-td class="text-center">
              {{ (serviceData.phosphate_point)? serviceData.phosphate_point : '-' }}
            </b-td>
            <b-td />
          </b-tr>
          <b-tr>
            <b-td>Demir Noktası</b-td>

            <b-td class="text-center">
              {{ (serviceData.iron_point)? serviceData.iron_point : '-' }}
            </b-td>
            <b-td />
          </b-tr>
          <!-- new lines -->
          <b-tr>
            <b-td>Zn (Çinko)</b-td>

            <b-td class="text-center">
              {{ (serviceData.zn)? serviceData.zn : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Mn(Mangan)</b-td>

            <b-td class="text-center">
              {{ (serviceData.mn)? serviceData.mn : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Ni(Nikel)</b-td>

            <b-td class="text-center">
              {{ (serviceData.ni)? serviceData.ni : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Serbest Alkalite</b-td>

            <b-td class="text-center">
              {{ (serviceData.free_alkalinity)? serviceData.free_alkalinity + 'ml/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              ml/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Toplam Yağ Ekstre</b-td>

            <b-td class="text-center">
              {{ (serviceData.total_oil_extract)? serviceData.total_oil_extract : '-' }}
            </b-td>
            <b-td class="text-center">
              ml/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Serbest Flor</b-td>

            <b-td class="text-center">
              {{ (serviceData.free_fluorine)? serviceData.free_fluorine : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>SiF6</b-td>

            <b-td class="text-center">
              {{ (serviceData.sif6)? serviceData.sif6 : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Bakteri</b-td>

            <b-td class="text-center">
              {{ (serviceData.bacteria)? serviceData.bacteria : '-' }}
            </b-td>
            <b-td />
          </b-tr>
          <b-tr>
            <b-td>Al Noktası</b-td>

            <b-td class="text-center">
              {{ (serviceData.al_point)? serviceData.al_point : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Zirkonyum</b-td>
            <b-td class="text-center">
              {{ (serviceData.zirconium)? serviceData.zirconium : '-' }}
            </b-td>
            <b-td class="text-center">
              mg/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Klor</b-td>
            <b-td class="text-center">
              {{ (serviceData.chlorine)? serviceData.chlorine + 'mg/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              mg/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Su Sertliği</b-td>
            <b-td class="text-center">
              {{ (serviceData.water_hardness)? serviceData.water_hardness + 'od' : '-' }}
            </b-td>
            <b-td class="text-center">
              od
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Kalay</b-td>
            <b-td class="text-center">
              {{ (serviceData.tin)? serviceData.tin + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>NaOH</b-td>
            <b-td class="text-center">
              {{ (serviceData.naoh)? serviceData.naoh : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Refraktometre Değeri</b-td>
            <b-td class="text-center">
              <span v-if="serviceData.refractometer">
                {{ serviceData.refractometer | toNumber }}
              </span>
              <span v-else>-</span>
            </b-td>
            <b-td class="text-center">
              %
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Kaplama Ağırlığı</b-td>
            <b-td class="text-center">
              {{ (serviceData.coating_weight)? serviceData.coating_weight : '-' }}
            </b-td>
            <b-td class="text-center">
              g/m2
            </b-td>
          </b-tr>
          <!-- new lines -->
          <b-tr>
            <b-td>Fe+2</b-td>
            <b-td class="text-center">
              {{ (serviceData.fe2)? serviceData.fe2 : '-' }}
            </b-td>
            <b-td class="text-center">
              g/l
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'

export default {
  name: 'ServiceCard',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
  },
  props: {
    serviceData: {
      type: Object,
      required: true,
    },
    formType: {
      type: String,
      default: '1',
    },
  },
  computed: {
    materialTypes() {
      return this.$store.getters['materialTypes/getMaterial_types']
    },
    usedOils() {
      return this.$store.getters['usedOils/getUsed_oils']
    },
    usedProcesses() {
      return this.$store.getters['usedProcesses/dataList']
    },
    usedProducts() {
      return this.$store.getters['usedProducts/dataList']
    },
    appearances() {
      return this.$store.getters['appearances/getAppearances']
    },
    foreignOilRatios() {
      return this.$store.getters['foreignOilRatios/getForeign_oil_ratios']
    },
    generalSituations() {
      return [
        {
          id: 1,
          title: 'Yeşil',
          color: '#447c00',
        },
        {
          id: 2,
          title: 'Sarı',
          color: '#b7af00',
        },
        {
          id: 3,
          title: 'Kırmızı',
          color: '#c90000',
        },
      ]
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    selectedData(data, id) {
      const res = data.filter(e => Number(e.id) === Number(id))
      if (res[0]) {
        return res[0].title
      }
      return null
    },
    getData() {
      if (this.materialTypes.length <= 0) {
        this.getMaterialTypes()
      }
      if (this.usedOils.length <= 0) {
        this.getUsedOils()
      }
      if (this.appearances.length <= 0) {
        this.getAppearances()
      }
      if (this.foreignOilRatios.length <= 0) {
        this.getForeignOilRatios()
      }
    },
    getMaterialTypes() {
      this.$store.dispatch('materialTypes/material_typesList', {
        select: [
          'material_types.id AS id',
          'material_types.title AS title',
        ],
      })
    },
    getUsedOils() {
      this.$store.dispatch('usedOils/used_oilsList', {
        select: [
          'used_oils.id AS id',
          'used_oils.title AS title',
        ],
      })
    },
    getAppearances() {
      this.$store.dispatch('appearances/appearancesList', {
        select: [
          'appearances.id AS id',
          'appearances.title AS title',
        ],
      })
    },
    getForeignOilRatios() {
      this.$store.dispatch('foreignOilRatios/foreign_oil_ratiosList', {
        select: [
          'foreign_oil_ratios.id AS id',
          'foreign_oil_ratios.title AS title',
        ],
      })
    },
  },
}
</script>
