<template>
  <b-card title="Servis Geçmişi">
    <app-collapse
      v-if="services.length > 0"
      accordion
    >
      <app-collapse-item
        v-for="(service,key) in services"
        :key="key"
        :title="'Servis Formu ' + moment(service.analysed).format('DD.MM.YYYY')"
      >
        <service-card
          v-if="service.formType === '1'"
          :service-data="service"
        />
        <service-card2
          v-if="service.formType === '2'"
          :service-data="service"
        />
      </app-collapse-item>
    </app-collapse>
    <div v-else>
      <b-alert
        variant="info"
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon
            icon="InfoIcon"
            size="20"
          />
          <p>Makine için servis kaydı bulunamadı.</p>
        </div>
      </b-alert>
    </div>
  </b-card>
</template>
<script>
import { BCard, BAlert } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ServiceCard from '@/views/Machines/View/ServiceCard.vue'
import ServiceCard2 from '@/views/Machines/View/ServiceCard2.vue'

export default {
  name: 'ServiceHistory',
  components: {
    BCard,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    ServiceCard,
    ServiceCard2,
  },
  computed: {
    services() {
      return this.$store.getters['services/getServices']
    },
  },
  created() {
    this.getServices()
  },
  methods: {
    getServices() {
      this.$store.dispatch('services/servicesList', {
        where: {
          'services.id_machines': this.$route.params.id,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
